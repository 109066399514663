




















import { defineComponent } from "@vue/composition-api";

interface IHeader {
  width: number;
  value: string;
}

interface IResultData {
  date: string;
  status: string;
}

export default defineComponent({
  name: "HistoryTable",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    headers(): IHeader[] {
      return [
        { width: 140, value: "date" },
        { width: 190, value: "status" },
      ];
    },
    resultData(): IResultData[] {
      return this.items as IResultData[];
    },
  },
});
