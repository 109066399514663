var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"monitoring-table monitoring-table__content",attrs:{"id":"virtual-scroll-table"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.resultData),function(event,eventIndex){return _c('tr',{key:event.id},[_vm._l((_vm.headers.slice(0, 5)),function(header,index){return _c('td',{key:("" + (event.id) + (header.id)),class:"monitoring-table__td pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' }),attrs:{"data-test-id":"tableCell"}},[(!index && event.steps)?_c('OpenCardButton',{on:{"open":function($event){return _vm.openModal(event)}}}):_vm._e(),(header.value === _vm.fields.NUMBER)?_c('div',[_vm._v(" "+_vm._s(eventIndex + 1)+" ")]):_c('div',[_vm._v(" "+_vm._s(event[header.value])+" ")])],1)}),(!!event.steps)?_c('td',{class:"monitoring-table__td pa-0",style:({ width: ((_vm.stepCellWidth * event.steps - _vm.stepCellWidth * (event.steps !== 4 ? 0.5 : 0)) + "px") }),attrs:{"data-test-id":"tableCell","colspan":event.steps}},[_c('div',{class:[
              'tk-journal__element pl-3 d-flex align-center justify-end',
              {
                'error-status': event.status === _vm.TK_STATUS.ERROR,
                'success-status': event.status === _vm.TK_STATUS.SUCCESS,
                'waiting-status': event.status === _vm.TK_STATUS.PENDING,
                'not-last': _vm.headers.length > event.steps + 5,
              } ],style:({ paddingRight: (_vm.imgRightPadding + "px") })},[(event.status === _vm.TK_STATUS.ERROR)?_c('v-tooltip',{attrs:{"fixed":"","z-index":200,"right":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.errorIcon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"tk-journal__tooltip"},[_c('div',{staticClass:"tk-journal__tooltip-text mb-2"},[_c('div',[_vm._v(_vm._s(event.description))]),_c('div',[_vm._v("Текст сообщения: "+_vm._s(event.textMessage))])])])]):(event.status === _vm.TK_STATUS.SUCCESS)?_c('v-tooltip',{attrs:{"fixed":"","z-index":200,"top":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.successIcon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"tk-journal__tooltip"},[_c('div',{staticClass:"tk-journal__tooltip-text"},[_vm._v(" "+_vm._s(event.description)+" "),_c('div',[_vm._v("Текст сообщения: "+_vm._s(event.textMessage))])])])]):_c('v-img',{attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.pendingIcon}})],1)]):_vm._e(),(event.steps !== 4)?_c('td',{staticClass:"monitoring-table__td pa-0",style:({ width: ((_vm.stepCellWidth * (4 - event.steps) + _vm.stepCellWidth * 0.5 * (event.steps !== 4 && !!event.steps ? 1 : 0)) + "px") }),attrs:{"data-test-id":"tableCell","colspan":4 - event.steps}}):_vm._e()],2)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }