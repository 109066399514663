import { v4 as uuid } from "uuid";
import { fields } from "./fields";

const cellElementWidth = 140;

export const tableHeaders = [
  {
    text: "№",
    tooltip: "Номер по порядку",
    defaultWidth: 40,
    value: fields.NUMBER,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Код ОИК",
    tooltip: "Код ОИК в ЦХЭД",
    defaultWidth: 150,
    value: fields.OIK_CODE,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Наименование ОИК",
    tooltip: "Наименование ОИК",
    defaultWidth: 200,
    value: fields.OIK_NAME,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Дата успешного обновления",
    tooltip: "Дата успешного обновления Справочников в ОИК",
    defaultWidth: 160,
    value: fields.SUCCESS_SYNC,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Дата последнего обновления",
    tooltip: "Дата последней попытки обновления Справочников в ОИК",
    defaultWidth: 200,
    value: fields.LAST_SYNC,
    isHiddenByDefault: false,
    isLink: false,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "1. Сформирован ТК для ОИК",
    tooltip: "1. Сформирован ТК для ОИК",
    defaultWidth: cellElementWidth,
    value: "tmp1",
    isSorted: false,
    isHandle: false,
  },
  {
    text: "2. ТК Отправлен через МЭДО",
    tooltip: "2. ТК Отправлен через МЭДО",
    defaultWidth: cellElementWidth,
    value: "tmp2",
    isSorted: false,
    isHandle: false,
  },
  {
    text: "3. Ожидание ответа от ОИК",
    tooltip: "3. Ожидание ответа от ОИК",
    defaultWidth: cellElementWidth,
    value: "tmp3",
    isSorted: false,
    isHandle: false,
  },
  {
    text: "4. Результат обновления",
    tooltip: "4. Результат обновления",
    defaultWidth: cellElementWidth,
    value: "tmp4",
    isSorted: false,
    isHandle: false,
  },
];
