import { v4 as uuid } from "uuid";
import { fields } from "./fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата успешного обновления справочников",
    className: "info-modal__element_title",
    value: fields.SUCCESS_SYNC,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последней попытки обновления справочников",
    className: "info-modal__element_title",
    value: fields.LAST_SYNC,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус последней попытки обновления справочников",
    className: "info-modal__element_title",
    value: fields.STATUS_RUSSIAN_TEXT,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Текст сообщения",
    className: "info-modal__element_title",
    value: fields.TEXT_MESSAGE,
  },
];
