



















































import { mapActions, mapGetters } from "vuex";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Filters from "./Filters.vue";
import InfoElements from "../InfoElements.vue";
import { defineComponent, ref } from "@vue/composition-api";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import OikInfoModal from "./OikInfoModal.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/tableHeaders";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import Table from "./Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import { getFormattedHeaders } from "@monorepo/monitoring/src/views/SyncLogOik/utils/getFormattedHeaders";

type headerMin = { width: number; defaultWidth: number; id: string; value: string; isLink: boolean; linkRights: string[] };

export interface IData {
  isSelectAllElement: boolean;
  tableHeaders: Record<string, string | number | boolean | undefined>[];
  getFormattedHeaders: (headers: headerMin[], visibleHeaders: headerMin[], difference: number) => headerMin[];
}

const SECTION_NAME = "syncLogOikView";

export default defineComponent({
  name: "OikView",
  components: {
    Filters,
    OikInfoModal,
    ScrollPanel,
    InfoElements,
    TableSettings,
    ExportButton,
    TableHeader,
    AutoRefreshButton,
    Table,
    SortPanelContainer,
    InfiniteScroll,
  },
  data(): IData {
    return {
      isSelectAllElement: false,
      tableHeaders,
      getFormattedHeaders,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, ["cells", "openedId", "infiniteId", "isTableLoading", "isOpenFilters", "autorefresh", "isLoadingChangeAutorefresh"]),
    ...mapGetters(SECTION_NAME, { data: "oikLogData", totalLength: "oikLogTotalLength", fieldFilters: "oikFieldFilters" }),
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isCanExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_EXPORT);
    },
    customFieldNames(): Record<string, string> {
      return {
        sort: "oikSort",
        refreshScroll: "refreshOikScroll",
        addSort: "addOikSort",
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, {
      refreshScroll: "refreshOikScroll",
      addFilter: "addOikFilter",
      getEventList: "getOikLogItems",
      getExportData: "getOikLogExportData",
    }),
    ...mapActions(SECTION_NAME, ["setIsOpenFilters", "setAutorefresh"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useRefreshTable(root, SECTION_NAME, 15000, "oikLogData", "refreshOikLogList");

    return {
      sectionName,
    };
  },
});
