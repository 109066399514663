































































import { get } from "lodash";
import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import HistoryTable from "./HistoryTable.vue";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { mapActions, mapGetters } from "vuex";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { modalElements } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/modalElements";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/SyncLogOik/utils/convertDataItemToTableView";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/viewTitle";
import { ISyncHistoryItem, ISyncLogElement } from "@monorepo/monitoring/src/views/SyncLogOik/types/syncLogElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "SyncLogInfoModal",
  components: {
    CardModalInfoElement,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    HistoryTable,
    ExportBtn,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      element: {} as ReturnType<typeof convertDataItemToTableView>,
      modalElements,
      viewUniqKey,
      fields,
      openedPanels: [0],
      cardMode: CardMode,
      isLoading: false,
      section: Sections.SYNC_LOG_OIK,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getOikCardElement: (val: string) => Promise<ISyncLogElement>;
          element: ReturnType<typeof convertDataItemToTableView>;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getOikCardElement(value)
            .then((data: ISyncLogElement) => {
              this.element = convertDataItemToTableView(data || {});
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("syncLogOikView", ["openedId", "oikLogData", "logStatusesLib"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isCanExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_EXPORT);
    },
    getItemElement(): unknown {
      return (key: string): string | undefined => {
        if (key === "status") {
          return this.convertStatus(get(this.element, key));
        } else {
          return get(this.element, key);
        }
      };
    },
    historyItems(this: { element: ISyncLogElement; convertStatus: (key: string) => string }) {
      return (
        (this.element.history as ISyncHistoryItem[])?.map((item) => {
          return {
            ...item,
            status: this.convertStatus(item.status),
          };
        }) || []
      );
    },
  },
  methods: {
    ...mapActions("syncLogOikView", ["changeOpenedId", "getOikCardElement"]),
    ...mapActions("app", ["openNewWindow"]),
    closeModal() {
      this.$emit("close");
    },
    convertStatus(key: string): string {
      return this.logStatusesLib.find((item: { title: string; value: string }) => item.value === key)?.title || "";
    },
    openCardPdf(id: string) {
      if (id) {
        this.openNewWindow(`${getFullPath(QUERY_PATH.GET_OIK_SYNC_LOG_PDF)}/${id}`);
      }
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "syncLogOikView", Sections.SYNC_LOG_OIK, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "syncLogOikView");

    return {
      addQueryOpenedId,
    };
  },
});
