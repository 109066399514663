














































































import { mapGetters } from "vuex";
import { defineComponent, computed } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/tableHeaders";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/SyncLogOik/utils/convertDataItemToTableView";
import { ISyncLogElement } from "@monorepo/monitoring/src/views/SyncLogOik/types/syncLogElement";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import { getFormattedHeaders } from "@monorepo/monitoring/src/views/SyncLogOik/utils/getFormattedHeaders";

export default defineComponent({
  components: {
    OpenCardButton,
  },
  data() {
    return {
      viewUniqKey,
      selectedKey: fields.ID,
      TK_STATUS,
      tableHeaders: [...tableHeaders],
      fields,
      imgRightPadding: 20,
      containerWidthBeforeResize: 0,
      containerWidthAfterResize: 0,
      containerWidthOnStart: 0,
      successIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
      errorIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/errorIcon.svg`,
      pendingIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/pendingIcon.svg`,
      messageIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/messageIcon.svg`,
    };
  },
  computed: {
    ...mapGetters("syncLogOikView", { data: "oikLogData" }),
    ...mapGetters("syncLogOikView", { getCells: "cells" }),
    stepCellWidth(this: { headers: Record<string, string | number | boolean | undefined>[] }): number {
      return this.headers.length > 0 ? (this.headers[this.headers.length - 1].width as number) : 0;
    },
    resultData() {
      return (this.data as ISyncLogElement[]).map(convertDataItemToTableView).map((item) => {
        return {
          ...item,
          steps: item.steps ? item.steps : 0,
        };
      });
    },
  },
  methods: {
    openModal(event: { transportContainer: { id: number } }) {
      if (event.transportContainer.id) {
        this.$store.dispatch("syncLogOikView/addOpenedId", event.transportContainer.id);
      }
    },
  },
  setup(props, { root }) {
    const resultTableHeaders = computed(() => tableHeaders as unknown[]);
    const { headers } = useTableCells(root, "syncLogOikView", resultTableHeaders, 1120, false, false, {}, getFormattedHeaders);
    return {
      headers,
    };
  },
});
